.parent-div {
  width: 55%;
  text-align: center;
  margin: auto;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .steps-section {
    display: none;
  }
}

.custom-card-number-class {
  padding: 30px 0px !important;
}

.ElementsApp .is-empty {
  background-color: white !important;
}

.add-card-btn {
  padding: 5px 10px;
}

.card-element .card-info {
  max-width: 500px;
  margin: 20px auto;
  background-color: white;
  border-radius: 12px;
  padding: 10px;
}
